@use "globals";

@keyframes openAlert {
  from {
    opacity: 0;
    height: 0;
    padding: 0 1rem;
  }
  to {
    opacity: 1;
    height: 100%;
    padding: 1rem;
  }
}
.alert.alert-danger {
  opacity: 1;
  height: 100%;
  padding: 1rem;
  overflow: hidden;
  display: block;
}
.alert.alert-danger.--open{
  animation-duration: .5s;
  animation-name: openAlert;
}
.alert.alert-danger.--closed{
  transition: ease all .5s;
  height: 0;
  padding: 0 1rem;
  opacity: 0;
  margin: 0;
}

@keyframes fillBar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.bar-fill {
  animation-duration: 3.3s;
  animation-name: fillBar;
  height: 5px;
  width: 100%;
  background: #00aa0080;
  border-radius: 0 0 4px 4px;
  top: 0;
  right: 0;

  &.--quick {
    animation-duration: 1.8s;
  }
}

.alert.alert-success.--bar {
  animation-duration: .5s;
  animation-name: openAlert;
  border-radius: 4px 4px 4px 0;
}