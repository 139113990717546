@use 'globals';

.left-nav {
  position: fixed;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  width: globals.$navOpenWidth;
  background: globals.$navBackground;
  overflow: auto;
  transition: globals.$animNormal;
  direction: rtl;

  /* Menu Scroll */
  &::-webkit-scrollbar {
    width: globals.$navScrollWidth;
  }
  &::-webkit-scrollbar-track {
    background: #333;
  }
  &::-webkit-scrollbar-thumb {
    background: #555;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  & * {
    direction: ltr;
  }

  .fill-bottom {
    flex-grow: 1;
    display: flex;

    .nav-section {
      border-bottom: none;
    }
  }

  .nav-section {
    border-bottom: 1px solid globals.$navBorder;
    display: grid;
    grid-template-columns: globals.$navClosedWidth 1fr;

    & .spacer {
      background-color: globals.$leftNavBackground;
      border-right: 1px solid globals.$navBorder;

      & > button {
        height: 100%;
        width: 100%;

        &:focus {
          box-shadow: none;
        }

        & > img {
          transition: globals.$animNormal;
          transform: rotate(0deg);
          width: 50%;
        }
      }
    }

    & > a {
      width: 100%;
      text-align: center;

      & > img {
        max-height: 100px;
        padding: 16px;
      }
    }

    .menu-container {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;

      a, button {
        transition: globals.$animNormal;
        display: flex;
        width: 100%;
        margin: 6px;
        border-radius: 60px 0 0 60px; // Left side fully rounded
        color: globals.$navSelected;
        text-decoration: none;
        font-size: 1rem;
        white-space: nowrap;

        &.active {
          background: globals.$navSelected;
          color: globals.$textDarkColor;

          & .navIcon {
            margin-left: 0;

            & > img {
              filter: invert(1);

              &.invert {
                filter: invert(0);
              }
            }
          }

          &:hover {
            background: globals.$navSelected;
          }
        }

        & > span {
          white-space: nowrap;
          margin: auto 0;
        }
      }

      a:hover {
        background: globals.$navHover;
      }

      button {
        margin: 0;
        padding: 6px;
      }

      & .navIcon {
        transition: globals.$animNormal;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        width: 52px;
        margin-right: 10px;
        margin-left: 20px;
        border-radius: 52px;
        border: 2px solid globals.$navSelected;

        & > img {
          height: 28px;
          width: 28px;
          filter: invert(0);

          &.invert {
            filter: invert(1);
          }
        }
      }
    }
  }
}

.main-window {
  transition: globals.$animNormal;
  margin-left: globals.$navOpenWidth;
  height: 100%;
}

.main-window.--nav-closed {
  margin-left: globals.$navClosedWidth;
}

.left-nav.--nav-closed {
  width: globals.$navClosedWidth;

  .nav-section .spacer > .btn > img {
    transform: rotate(-180deg);
  }

  .nav-section .menu-container {
    & > a, & > button {
      transform: translateX(-63px);
      .navIcon {
        margin-left: 0;
      }
    }
  }
}
