@use 'globals';
@use 'login';
@use 'nav';
@use 'components';
@use 'iconPicker';

html,
body,
#root,
.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  margin: 0;
}

body * {
  font-family: sofia-pro-soft, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', sans-serif;
  box-sizing: border-box;
}

//Cannot use calc in jsx styles
.beacon-placement {
  position: absolute;
  width: 64px;
  top: calc(50% - 88px);
  right: calc(50% - 2px);
}

//truncates content that's too long
.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}

//truncates content that's too long
//sometimes need no max width
.truncate-no-width {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

//Shockingly not a part of bootstrap?
.full-width, .fw {
  width: 100%;
}

.monospace {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-weight: 600;
}