.icon-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 6px;
  background-color: #eee;
  text-align: center;
  padding: 8px 8px 1rem 8px;
  width: 100%;

  img {
    border-radius: 4px;
    background-color: #404040;
    margin-bottom: 1rem;
  }
}