// global variables
$shadowColor: #00000080;
$yondarBlue: #00AEEF;


// Left nav variables
$leftNavBackground: #0F0F0F;
$navBorder: #5B5B5B;
$navBackground: linear-gradient(to top, #000000, #274B6C);
$navHover: #ffffff20;
$navSelected: white;

$navScrollWidth: 0; // this is bad ux. I had it at 10, but this makes the menu look inconsistent when the sidebar exists.
$navClosedWidth: 60px;
$navOpenWidth: 320px;

// button variables
$btnBorder: 2px solid white; //override bootstrap
$btnShadow: 0 1px 4px -1px rgba(0,0,0,.5);// box-shadow

// Header Variables
$headerColor: $yondarBlue;

// Animation Variables
$animNormal: ease all .35s;

// Text Variables
$textDarkColor: #212529;

// Bootstrap Overrides (must come before the import)
$enable-grid-classes: false;
$enable-cssgrid: true;
$theme-colors: (
  "primary": #40403f,
  "secondary": #7b7b7b,
  "danger": #EE3452,
  "success": #93E930,
  "warning": #FFE60C,
  "info": #4EB8FF,
  "light": #fafafa,
  "dark": #1a1a1a
);

//Import other global styles
@import '~bootstrap/scss/bootstrap';
@import url('https://use.typekit.net/mkx6hza.css');
