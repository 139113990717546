//This file is mostly for overwriting bootstrap

@use 'globals';

.btn {
  &.btn-fancy{
    transition: globals.$animNormal;
    border: globals.$btnBorder;
    box-shadow: globals.$btnShadow;

    &:hover, &:active, &:focus, &:disabled {
      border: globals.$btnBorder;
      box-shadow: globals.$btnShadow;
    }

    &:disabled {
      box-shadow: none;
    }
    
    &:active {
      transform: translateY(5px);
    }
  }

  &.btn-secondary {
    color: white;
  }

  &.btn-danger {
    color: white;
  }
}

.bg-header {
  background: globals.$headerColor;
}